@import '../../styles/variables';
@import './toggleTheme.scss';

.toggleTheme {
  &Default {
    @include toggleTheme(#b8b8b8, $theme-color-main, $white, $white, $white, $white);
  }

  &Light {
    @include toggleTheme(#b8b8b8, $theme-success-main, #4a4a4a, $white, $white, $white);
  }

  &GreyLight {
    @include toggleTheme(#b8b8b8, $black, #4a4a4a, $white, $white, $white);
  }

  &White {
    @include toggleTheme(#b8b8b8, $white, $white, $theme-color-main, $white, $theme-color-main);
  }

  &WhiteBlue {
    @include toggleTheme($white, $white, $theme-color-main, $theme-color-main, $theme-color-main, $theme-color-main);
  }

  &MediumBlueWhite {
    @include toggleTheme(#b8b8b8, $mediumblue, $white, $white, $white, $white);
  }

  &Black {
    @include toggleTheme(rgba(white, 0.2), rgba(white, 0.2), $darkgrey, white, $darkgrey, white);
  }

  &BlackAndWhite {
    @include toggleTheme($black, $white, $white, $black, $white, $black, 1px solid white);
  }
}
