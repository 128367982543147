@import '../../../styles/variables.scss';
@import '../../../styles/spacing.scss';
@import '../../../styles/spaced.scss';
@import '../../../styles/typography.scss';

.userManagementOverview {
  @include typography();
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: spacing(8);
  background-color: $white;
}

.title {
  padding: 0 spacing(4);
}

.subHeader {
  display: flex;
  justify-content: space-between;
  margin: 0 spacing(4) spacing(4);
}

.companies {
  display: flex;
  align-items: center;
}

.companyFilter {
  appearance: none;
  border-radius: 0;
  padding: spacing(2) spacing(9) spacing(2) spacing(3);
  background: url(../../../assets/icons/dropdownMenu.svg) no-repeat right spacing(3) center;
  border: solid 1px #e6e4e2;
  font-size: $small-font-size;
  font-family: $font-family-strong;
}

.userControls {
  display: flex;
  @include spaced-horizontal(spacing(8));
}

.statusCounters {
  @include spaced-horizontal(spacing(4));
  display: flex;
}

.counter {
  @include spaced-horizontal(spacing(2));
  font-family: $font-family-strong;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .value {
    display: inline-block;
    padding: 1px 4px;
    color: $white;
    border-radius: 2px;

    &.error {
      background-color: $darkred;
    }

    &.active {
      background-color: $green;
    }
  }
}
