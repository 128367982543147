@import '../../../../styles/spaced.scss';
@import '../../../../styles/spacing.scss';
@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';
@import '../../../Modal/variables.scss';

$formMinWidth: 300px;
$inputsSpace: spacing(2);
$formColumnsSpace: spacing(1);

.form {
  @include spaced-vertical($modalDefaultLayoutVerticalSpacing);
  min-width: $formMinWidth;

  .inputs {
    @include spaced-vertical($inputsSpace);

    .formRow {
      @include spaced-horizontal(spacing(4));
      display: flex;
      flex-direction: row;

      &.actionsRow {
        justify-content: flex-end;
      }

      .formColumn {
        @include spaced-vertical($formColumnsSpace);
        flex: 1 0 0;
      }
    }
  }
}

.actions {
  margin-left: auto;
}
