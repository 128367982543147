@import '../../styles/variables';

.search {
  width: 100%;
  display: flex;
  align-items: center;
}

.icon {
  display: inline-block;
  transform: rotate(90deg);
  font-size: 18px;
  flex: 0;
}

.input {
  flex: 1;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-align: left;
  line-height: 40px;
  font-family: $font-family-headings;
  font-size: $filter-option-fontsize;
  text-transform: uppercase;

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $theme-color-main;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $theme-color-main;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $theme-color-main;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: $theme-color-main;
  }
}
