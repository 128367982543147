@import '../../../styles/variables.scss';
@import '../../../styles/spaced.scss';
@import '../SideSubMenu.mixins.scss';

.label {
  display: block;
  width: 100%;
  font-size: 12px;
}

.textarea {
  @include sideSubMenuInput();

  height: 150px;
  max-height: 150px;
}
