@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/inlineInput.scss';

.filterInput {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;

  .input {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    @include inlineInput();

    background-color: transparent;

    &:not(:focus) {
      opacity: 0;
    }

    &:focus + * {
      opacity: 0;
    }
  }

  .textAndInput {
    position: relative;
  }

  .searchIcon {
    @include clickable();
    font-size: 14px;
    color: $disabledTextColor;

    &.active {
      color: $darkblue;
    }
  }
}
