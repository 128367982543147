@import '../../styles/variables';
@import '../../styles/z-indices';

$side-menu-width: 375px;
$side-menu-background-color: $white;
$side-menu-color: $darkblue;

.sideMenuOverlay {
  &:not(.active) {
    display: none;
  }

  &.active {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($black, 0.2);

    cursor: pointer;
  }

  z-index: $actionPanelIndex;
}

.sideMenu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -$side-menu-width;
  width: $side-menu-width;
  overflow: hidden;

  transition: left 0.3s ease-out;

  background-color: $side-menu-background-color;
  color: $side-menu-color;

  &.active {
    left: 0;
    box-shadow: 10px 0px 18px rgba($black, 0.2);
  }

  z-index: $actionPanelIndex;
}

.drillDownLevel {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;

  &.left {
    left: 0;

    &:not(.active) {
      transform: translate(-100%, 0);
    }
  }

  &.right {
    right: 0;

    &:not(.active) {
      transform: translate(100%, 0);
    }
  }

  &.left,
  &.right {
    transition: transform 0.3s;
  }
}
