@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/spaced';
@import '../../styles/spacing.scss';

$filters-outer-space: 100px !default;
$filter-actions-vertical-space: 40px !default;
$filters-font-color: $white !default;
$filter-action-background: #59aed9 !default;
$smallSpacing: 20px;
$largeSpacing: 60px;
$minFilterWidth: 150px;
$maxFilterWidth: 300px;

.filterOptions,
.filterSettingsOptions {
  @include spaced-horizontal($largeSpacing);

  display: flex;
  flex-direction: row;
  margin-left: spacing(8);

  .daterangeFilter {
    display: flex;
    flex-direction: column;

    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  .roleSpecificFilters {
    display: flex;
    flex-direction: column;
    width: 250px;

    @include respond-to('small') {
      flex-direction: column;
      margin-right: 0 !important;
      margin-left: 0 !important;
      margin-bottom: spacing(4);
      width: 100%;
    }

    .filterDivider {
      width: 100%;
      height: 20px;

      @include respond-to('small') {
        display: none;
      }
    }

    .roleSpecificFilter {
      width: 100%;
      margin-bottom: spacing(4);

      &.disabled {
        opacity: 0.3;
        cursor: not-allowed;
        pointer-events: none;
      }

      @include respond-to('small') {
        min-width: 100%;
        margin: 0;
        margin-top: spacing(4);
      }
    }
  }
}

.filterSettingsOptions .roleSpecificFilters {
  width: 330px;
  padding-top: spacing(4);
}

.userInfo {
  // hide user column in filters on phones and tablets
  @include respond-to('large') {
    display: none;
  }
}

.timeWindowFilters {
  display: flex;

  @include spaced-horizontal($smallSpacing);

  .timeWindowFilter {
    width: 170px;
  }
}

.optionTitle {
  display: block;
  font-size: $filter-option-title-fontsize;
  text-transform: uppercase;
  white-space: nowrap;
}

.optionNotice {
  padding-top: $filter-list-vertical-margin;
}

.icon {
  color: $theme-color-main;
  background-color: $white;
}
