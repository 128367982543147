@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/spacing.scss';
@import '../../styles/inlineInput.scss';
@import './variables.scss';

$fontSize: 12px;
$bodyRowVerticalPadding: ($bodyRowHeight - $fontSize - $borderWidth) / 2;
$headerRowVerticalPadding: ($headerRowHeight - $fontSize - $borderWidth) / 2;
$rowHorizontalPadding: spacing(5);
$borderColor: $rowBorderColor;

.table {
  flex: 1;
  height: 100%;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: stretch;

  box-sizing: border-box;
  border-bottom: $borderWidth $borderColor solid;
}

.cell {
  font-size: $fontSize;
  line-height: $fontSize;
  @include overflowEllipsis();
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
}

.body.row {
  position: absolute;
  left: 0;
  right: 0;

  height: $bodyRowHeight;

  .cell {
    height: $bodyRowHeight - $borderWidth;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
  }

  &.even {
    &,
    .cell {
      background-color: $rowEvenColor;
    }
  }

  &.odd {
    &,
    .cell {
      background-color: $rowOddColor;
    }
  }
}

.header.row {
  font-family: $font-family-regular;
  text-transform: uppercase;
  height: $headerRowHeight;

  .cell {
    height: $headerRowHeight - $borderWidth;
    padding-top: $headerRowVerticalPadding;
    padding-bottom: $headerRowVerticalPadding;

    &.sortable {
      @include clickable();
    }
  }
}
