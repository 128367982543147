@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

@mixin sideSubMenuInput {
  font-family: $font-family-regular;
  font-size: 14px;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  display: block;
  padding: 8px;
  border: 1px $inputBorderColor solid;
}

@mixin button() {
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  text-align: center;
  text-transform: uppercase;
  font-family: $font-family-strong;
  font-size: 14px;
  border-radius: 2px;

  &:not(:disabled) {
    @include clickable();
  }
}
