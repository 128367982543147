@import '../../styles/variables.scss';
@import '../../styles/buttonSize.scss';
@import '../../styles/fontSize.scss';
@import '../../styles/formControlSize.scss';
@import '../../styles/spacing.scss';
@import '../../styles/spaced.scss';

// Same icon size regardless of the button size
$iconSize: 20px;

@mixin withIcon($size) {
  $sideSpacing: buttonHorizontalPadding($size);
  // Increases the padding to place the icon there
  $iconSidePadding: $sideSpacing + $iconSize + spacing(1);

  &.withLeftIcon {
    padding-left: $iconSidePadding;
    .icon {
      left: $sideSpacing;
    }
  }
  &.withRightIcon {
    padding-right: $iconSidePadding;
    .icon {
      right: $sideSpacing;
    }
  }
}

.button,
.textOnly {
  outline: none;
  font-family: $font-family-strong;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &:focus {
    outline: 1px solid $darkblue;
  }
}

.button {
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;

  &.extraSmall {
    @include buttonSize($formControlSizeExtraSmall);
    @include withIcon($formControlSizeExtraSmall);
  }
  &.small {
    @include buttonSize($formControlSizeSmall);
    @include withIcon($formControlSizeSmall);
  }
  &.medium {
    @include buttonSize($formControlSizeMedium);
    @include withIcon($formControlSizeMedium);
  }
  &.large {
    @include buttonSize($formControlSizeLarge);
    @include withIcon($formControlSizeLarge);
  }
  &.extraLarge {
    @include buttonSize($formControlSizeExtraLarge);
    @include withIcon($formControlSizeExtraLarge);
  }

  &.withIcon {
    position: relative;
    display: flex;
    align-items: center;

    .icon {
      position: absolute;
      font-size: $iconSize;
    }
  }
}

.inlineBlock {
  display: inline-block;
}

.block {
  display: block;
  width: 100%;
}

.primary {
  background-color: $theme-color-active;
  border-color: transparent;
  color: $darkblue;

  &:not(:disabled) {
    &:hover {
      background-color: $darkblue;
      color: $white;
    }
  }

  &:disabled {
    opacity: 1;
    background-color: $lightgrey;
    color: $disabledTextColor;
  }
}

.secondary {
  background-color: $white;
  border-color: $darkblue;
  color: $darkblue;

  &:hover:not(:disabled) {
    background-color: $darkblue;
    color: $white;
  }
}

.tertiary {
  border-color: #0693ca;
  background-color: #0693ca;
  color: $white;

  &:hover:not(:disabled) {
    border-color: #077ab7;
    background-color: #077ab7;
  }

  &:disabled {
    opacity: 1;
    background-color: #ccc8c5;
  }
}

.quaternary {
  background-color: $darkblue;
  border-color: $white;
  color: $white;

  &:hover:not(:disabled) {
    border-color: $darkblue;
    background-color: rgba($white, 0.2);
  }
}

.textOnly {
  background-color: transparent;
  color: $darkblue;
  font-size: $fontSizeButton;
  border: none;

  &:hover:not(:disabled) {
    text-decoration: underline;
  }
}
