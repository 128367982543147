@import '../../../../styles/spaced.scss';
@import '../../../../styles/spacing.scss';
@import '../../../../styles/variables.scss';

$formItemSpace: spacing(1);
$feedbackLineHeight: 14px;

.formItem {
  @include spaced-vertical($formItemSpace);

  .label {
    @include spaced-vertical($formItemSpace);
    .labelText {
      color: $darkblue;
      display: block;
      font-family: $font-family-strong;
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  .feedback {
    display: block;
    line-height: $feedbackLineHeight;
    min-height: $feedbackLineHeight; // Reserves the space
    font-size: 12px;
    font-family: $font-family-regular;
    color: $theme-errors-main;
    text-transform: capitalize;
  }
}
