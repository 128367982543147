@import './SideMenu.module';
@import '../../styles/variables';
@import '../Buttons/CornerCloseButton/CornerCloseButton.module';
@import '../Header/variables.scss';

/*
  Corner close button should have same height as $header-height.
  We want to show the button when the side menu opens in the exact same place
  as where the hamburguer menu was, thus a user can 'open' and 'close' the menu
  in clicking in the same area.
*/
$sideMenuCloseButtonSize: $header-height;

.sideMenu {
  &Header {
    flex-grow: 0;
    flex-shrink: 0;

    height: $header-height;

    display: flex;
    flex-direction: row;
    align-items: center;

    .closeIconWrapper {
      flex-grow: 0;
      flex-shrink: 0;

      .closeSideMenuIcon {
        @include cornerCloseButtonSize($sideMenuCloseButtonSize);
      }
    }

    .headerLogo {
      flex-grow: 1;
      flex-shrink: 1;
      text-align: center;
    }
  }
}
