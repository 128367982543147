@import '../../../styles/mixins.scss';
@import '../../../styles/spaced';
@import '../../../styles/spacing.scss';
@import '../../../styles/variables';
@import '../SideSubMenu.mixins.scss';
@import '../SideSubMenu.variables.scss';

.submitButton {
  @include button();
  @include spaced-horizontal(spacing(2));

  &.active {
    color: $darkblue;
    background-color: $theme-color-active;

    &.clickable {
      &:hover {
        color: white;
        background-color: $darkblue;
      }
    }
  }

  &.disabled {
    color: $disabledTextColor;
    background-color: $mediumgrey;
  }

  &.success {
    color: $darkblue;
    background-color: $white;

    .submittedIcon {
      display: inline-block;
      vertical-align: middle;
      width: $icon-size;
      height: $icon-size;
      background-image: url('../../../assets/icons/oke.svg');
    }
  }

  .text {
    display: inline-block;
    vertical-align: middle;
    height: $icon-size;
    line-height: $icon-size;
  }
}
