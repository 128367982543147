@import '../../styles/spaced';
@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/spacing.scss';

.sideMenuFooter {
  $item-spacing: spacing(2);
  $distance-footer-to-bottom: spacing(4);
  $user-icon-size: 46px;

  flex-grow: 0;
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  @include spaced-vertical($item-spacing);
  padding-bottom: $distance-footer-to-bottom;

  .userIcon {
    flex: 0 0 $user-icon-size;
    @include square($user-icon-size);
    background-size: $user-icon-size;
    border-radius: $user-icon-size;
    background-color: sandybrown;
  }

  .logout {
    font-family: $font-family-regular;
    font-size: 16px;
    @include clickable();

    &:hover {
      text-decoration: underline;
    }
  }
}
