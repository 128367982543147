@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/formControl.scss';
@import '../../styles/formControlSize.scss';
@import '../../styles/spaced.scss';
@import '../../styles/spacing.scss';

$borderWidth: $formControlBorderWidth;
$spaceCheckboxAndLabel: spacing(2);

.option {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  @include clickable();
  font-family: $font-family-regular;
  white-space: nowrap;

  border: none;
  outline-width: $formControlBorderWidth;
  outline-style: solid;
  padding: $formControlPadding;
  font-size: $formControlFontSize;
  line-height: $formControlFontSize;
  box-sizing: border-box;
  min-height: $formControlHeight;
  @include spaced-horizontal($spaceCheckboxAndLabel);

  &.selected {
    color: $white;
    background-color: $lightblue;
    outline-color: transparent;
  }

  &:not(.selected) {
    color: $darkblue;

    &:not(:hover) {
      background-color: $white;
    }

    &:hover {
      background-color: $broken-white;
    }

    &:not(:focus) {
      outline-color: transparent;
    }

    &:focus {
      outline-color: $formControlActiveBorderColor;
      z-index: 1;
    }
  }
}
