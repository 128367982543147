@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

$table-header-font-size: 10px;
$companyNameColumnWidth: 300px;
$dateRangeColumnWidth: 150px;
$rowHoverColor: $veryLightBlue;
$defaultHorizontalCellPadding: spacing(5);

.row {
  &.header .cell {
    font-family: $font-family-strong;
    font-size: $table-header-font-size;
  }

  &.body {
    font-family: $font-family-regular;
    color: $darkblue;
    @include clickable();

    &:hover,
    &:hover .cell {
      background-color: $rowHoverColor;
    }
  }

  .cell {
    padding-right: $defaultHorizontalCellPadding;
    padding-left: $defaultHorizontalCellPadding;
  }

  .companyName {
    flex-basis: $companyNameColumnWidth;
  }

  .dateRangeStart,
  .dateRangeEnd {
    flex-basis: $dateRangeColumnWidth;
  }
}
