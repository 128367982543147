@import '../../styles/spaced';
@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/spacing.scss';
@import 'SideMenuColors.module';

.sideMenuItem {
  @include clickable;

  border-bottom: 1px $menuItemBorderColor solid;
  padding: 14px spacing(2);
  font-family: $font-family-regular;
  font-size: $default-font-size;
  text-align: left;
  text-decoration: none;

  &:hover {
    background-color: $menuItemHoverColor;
  }

  &Wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $textColor;
    text-decoration: none;
  }

  &Text {
    flex-grow: 1;
    flex-shrink: 1;
  }

  &InternalIcon,
  &ExternalIcon {
    flex-grow: 0;
    flex-shrink: 0;
    width: 20px;
    height: 15px;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    background-color: $lightblue;
  }

  &InternalIcon {
    mask-image: url('../../assets/icons/chevron.svg');
    transform: rotate(270deg);
  }

  &ExternalIcon {
    mask-image: url('../../assets/icons/external.svg');
  }
}
