@import 'functions';
@import 'animations';
@import 'variables';
@import './shapes/round.scss';

//==================
// Responsive:
// https://css-tricks.com/snippets/sass/mixin-manage-breakpoints/
//==================
$breakpoints: (
  'small': (
    max-width: $breakpoint-small,
  ),
  'medium': (
    max-width: $breakpoint-medium,
  ),
  'large': (
    max-width: $breakpoint-large,
  ),
) !default;

/// Mixin to manage responsive breakpoints
/// @author Hugo Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
/// @example
/// .myModule {
///   @include respond-to('small') {
///     font-size: 12px;
///   }
/// }
@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }
  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

//==================
// General
//==================
@mixin maxHeight {
  height: 100%;
}

@mixin maxWidth {
  width: 100%;
}

@mixin maxSize {
  @include maxWidth;
  @include maxHeight;
}

//==================
// Positions
//==================
@mixin absoluteBottom {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: auto;
}

@mixin absoluteTop {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: auto;
}

@mixin absoluteLeft {
  position: absolute;
  bottom: 0;
  right: auto;
  left: 0;
  top: 0;
}

@mixin absoluteRight {
  position: absolute;
  bottom: 0;
  right: 0;
  left: auto;
  top: 0;
}

@mixin absoluteCenter {
  position: absolute;
  left: 50%;
  top: 50%;
  height: auto;
  max-height: 100%;
  transform: translate(-50%, -50%);
}

@mixin overflowEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

//==================
// Backgrounds
//==================
@mixin stripedBackground($color, $size: 20px) {
  background: repeating-linear-gradient(135deg, $white, $white #{$size}, #{$color} #{$size}, #{$color} #{$size * 2});
}

@mixin axisMeasuremnets($color: #333, $orientation: 'horizontal') {
  $deg: null;

  position: absolute;

  @if $orientation == 'vertical' {
    $deg: 0;
    content: '';
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
  } @else {
    $deg: 90deg;
  }

  background: repeating-linear-gradient($deg, transparent, transparent 1px, #{$color} 2px, #{$color} 2px);
}

//==================
// Terminal view / Ship nose
//==================
@mixin shipNose($color: #001f4b, $height: null) {
  background-color: $color;

  position: absolute;
  margin: 0;
  overflow: hidden;

  transform: rotate(225deg);

  // See shorthand for border radius and the corners they affect
  // https://developer.mozilla.org/en-US/docs/Web/CSS/border-radius
  border-radius: 10% 25%;

  @if $height {
    // ==================
    // For fixed height
    // ==================
    $diameter-height: width-from-diameter($height);
    $ship-height: unquote($diameter-height + 'px');
    top: unquote($diameter-height/5 + 'px');
    left: -unquote($diameter-height/2 + 'px');
    height: $ship-height;
    width: $ship-height;
  } @else {
    // ==================
    // For height based on width
    // ==================
    left: -6.25%;
    top: 12.75%;
    padding-left: 12.5%;
    padding-bottom: 12.5%;
  }
}

@mixin timelineItem($color: #001f4b) {
  background-color: $color;
  &:hover {
    .vis-item-content {
      text-decoration: underline;
    }
  }
}

@mixin vesselTypeFilter($index, $top: null, $right: null, $bottom: null, $left: null) {
  position: absolute;

  @if ($top) {
    top: $top;
  }
  @if ($right) {
    right: $right;
  }
  @if ($bottom) {
    bottom: $bottom;
  }
  @if ($left) {
    left: $left;
  }

  z-index: $index;
}

@mixin shipWarningIcon($fontSize) {
  font-size: $fontSize;
  color: $theme-errors-main;
  pointer-events: auto;
  cursor: pointer;
  background-image: radial-gradient(circle at center, $white 40%, transparent 40%);
}

@mixin customScrollBar() {
  $scrollbar-background-color: #e7e7e7;
  $scrollbar-thumb-background-color: #c6c6c6;
  $scrollbar-border-radius: 10px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 9px;

    &-track {
      -webkit-border-radius: $scrollbar-border-radius;
      background-color: $scrollbar-background-color;
      border-radius: $scrollbar-border-radius;
    }

    &-thumb {
      -webkit-border-radius: $scrollbar-border-radius;
      border-radius: $scrollbar-border-radius;
      background-color: $scrollbar-thumb-background-color;
    }
  }
}

@mixin clickable {
  cursor: pointer;
  user-select: none;
}

@mixin materialIconFontSize($fontSize) {
  :global(.material-icons) {
    font-size: $fontSize;
  }
}

@mixin square($size) {
  width: $size;
  height: $size;
}

@mixin spaced($spacing, $extra: 0) {
  $margin-top: 0;
  $margin-right: 0;
  $margin-bottom: 0;
  $margin-left: 0;
  @if length($extra) == 1 {
    $margin-top: nth($extra, 1);
    $margin-right: nth($extra, 1) - $spacing / 2;
    $margin-bottom: nth($extra, 1);
    $margin-left: nth($extra, 1) - $spacing / 2;
  } @else {
    @if length($extra) == 2 {
      $margin-top: nth($extra, 1);
      $margin-right: nth($extra, 2) - $spacing / 2;
      $margin-bottom: nth($extra, 1);
      $margin-left: nth($extra, 2) - $spacing / 2;
    } @else {
      @if length($extra) == 4 {
        $margin-top: nth($extra, 1);
        $margin-right: nth($extra, 2) - $spacing / 2;
        $margin-bottom: nth($extra, 3);
        $margin-left: nth($extra, 4) - $spacing / 2;
      }
    }
  }

  margin-top: $margin-top;
  margin-right: $margin-right;
  margin-bottom: $margin-bottom;
  margin-left: $margin-left;

  & > * {
    margin-left: $spacing / 2;
    margin-right: $spacing / 2;
  }
}

@mixin circumference($size, $borderColor) {
  @include round($size);
  border: 2px $borderColor solid;
  box-sizing: border-box;
}

@mixin circumferenceWithImage($size, $verticalMargin, $borderColor, $image) {
  @include circumference($size, $borderColor);
  background-image: $image;
  background-repeat: no-repeat;
  background-position: center;
  margin: $verticalMargin auto;
}

@mixin spinner($size, $margin, $backgroundBorderColor: $mediumgrey, $foregroundBorderColor: $black) {
  @include circumference($size, $backgroundBorderColor);
  border-bottom-color: $foregroundBorderColor;
  animation: spinner-rotate 0.75s linear infinite;
  margin: $margin auto;
}

@mixin centerWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

// Can be used when you want to configure the position itself.
@mixin transparantOverlayWithoutPosition {
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.4s linear, opacity 0.4s linear;
  will-change: visibility, opacity;
  background-color: $black;

  &.active {
    visibility: visible;
    opacity: 0.5;
    cursor: pointer;
  }
}

@mixin transparantOverlay {
  @include transparantOverlayWithoutPosition();
  position: fixed;
  height: 100vh;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin actionMenuItem {
  border-left: 1px $header-menu-border-color solid;
  font-size: $header-action-font-size;

  &.active {
    color: $white;
    background-color: $lightblue;
  }
}

@mixin preventMarginCollapseWithPreviousElement {
  &:before {
    content: ' ';
    display: table;
    height: 0;
  }
}

@mixin backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

@mixin responsiveIcon {
  $smallFontSize: 12px;
  $mediumFontSize: 18px;
  $largeFontSize: 24px;
  $extraLargeFontSize: 30px;

  $smallPadding: 3px;
  $mediumPadding: 5px;
  $largePadding: 8px;

  $withPaddingSmallSize: $mediumFontSize + 2 * $smallPadding;
  $withPaddingMediumSize: $largeFontSize + 2 * $mediumPadding;
  $withPaddingLargeSize: $extraLargeFontSize + 2 * $largePadding;

  @include clickable();
  background-color: $darkblue;
  color: $white;
  text-align: center;
  box-sizing: border-box;
  transition: font-size 0.3s, padding 0.3s;

  &.sm {
    width: $withPaddingSmallSize;
    height: $withPaddingSmallSize;
    line-height: $withPaddingSmallSize;
    font-size: $smallFontSize;
    &:hover {
      font-size: $mediumFontSize;
    }
  }

  &.md {
    width: $withPaddingMediumSize;
    height: $withPaddingMediumSize;
    line-height: $withPaddingMediumSize;
    font-size: $mediumFontSize;
    &:hover {
      font-size: $largeFontSize;
    }
  }

  &.lg {
    width: $withPaddingLargeSize;
    height: $withPaddingLargeSize;
    line-height: $withPaddingLargeSize;
    font-size: $largeFontSize;
    &:hover {
      font-size: $extraLargeFontSize;
    }
  }
}

@mixin button($theme: 'primary') {
  @include clickable();
  text-transform: uppercase;
  font-family: $font-family-headings;
  margin: 0;
  outline: none;
  transition: background-color 0.3s, color 0.3s;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  // e.g nextButton
  @if $theme == 'primary' {
    background-color: $theme-color-yellow;
    color: $theme-color-main;

    &:disabled {
      opacity: 1;
      background-color: $lightgrey;
      color: $disabledTextColor;
    }

    &:not([disabled]) {
      &:hover {
        background-color: $theme-color-main;
        color: $white;
      }
    }
  }
  // e.g closeButton
  @else if $theme == 'secondary' {
    background-color: $theme-background-color;
    color: $theme-color-main;

    &:hover {
      background-color: $theme-color-main;
      color: $white;
    }
  }
}

@mixin fadeIn {
  opacity: 0;
  animation: fade-in 0.7s ease-out 0.3s forwards;
}

@mixin visuallyHidden() {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
