@import '../../styles/variables.scss';
@import '../../styles/spacing.scss';
@import '../../styles/spaced.scss';
@import '../../styles/typography.scss';

.managementPage {
  @include typography();
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  overflow-y: auto;
  max-height: 100%;
  position: relative;
  padding-top: spacing(8);
  background-color: $white;

  > h1 {
    padding: 0 spacing(4);
  }
}

.managementPageCentered {
  margin-left: auto;
  margin-right: auto;
  padding: 0 spacing(4) spacing(8);
  max-width: 800px;
  width: 100%;
}
