@import '../../../../styles/variables.scss';
@import '../../../Modal/variables.scss';

.column {
  padding: $modalDefaultLayoutColumnPadding;

  &.form {
    flex-grow: 1;
    background-color: $white;
  }

  &.sidePanel {
    display: flex;
    flex-direction: column;
    background-color: $verylightgrey;
  }
}
