@import '../../../../styles/variables.scss';
@import '../../../../styles/spaced.scss';
@import '../../../../styles/spacing.scss';
@import '../../../../styles/mixins.scss';

$fontSizeTitle: 22px;
$fontSizeRegular: 14px;

.sidePanel {
  width: 280px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  flex-grow: 1;
  color: $darkblue;

  .sidePanelItem {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @include spaced-vertical-no-margin-collapse(spacing(4));
  }

  // Holds the title and the description.
  .explanation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @include spaced-vertical-no-margin-collapse(spacing(2));

    .title {
      font-family: $font-family-strong;
      font-size: $fontSizeTitle;
    }

    .text {
      font-family: $font-family-regular;
      font-size: $fontSizeRegular;
    }
  }

  .action {
    color: inherit;
    align-items: center;
    padding: 0;
    @include clickable();
    font-size: $fontSizeRegular;
    display: block;
    outline: none;
    font-family: $font-family-strong;

    &.deleteAccount {
      text-decoration: underline;
    }

    &.deactivateAccount {
      display: flex;
      flex-direction: row;
      @include spaced-horizontal(spacing(1));
    }
  }
}
