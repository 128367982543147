@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/spacing.scss';
@import '../../styles/z-indices';

$selectOptionHeight: 40px;
$queryInputTextFontSize: 24px;
$resetQueryButtonRight: spacing(1);

.backdrop {
  @include backdrop;
  z-index: $dropdownBackdropIndex;
}

.datesDropdown {
  &.isActive {
    z-index: $datesDropdownIndex;
  }
}

.dropdown {
  &.datesDropdown {
    &.isActive {
      .dropdownListWrapper {
        z-index: $datesDropdownIndex;
      }
    }
  }

  &.autoDropdown {
    &.isActive {
      .dropdownListWrapper {
        z-index: $autoDropdownIndex;
      }
    }

    .selectedOption {
      padding: 0 !important;
      .queryTextInput {
        width: 100%;
        box-sizing: border-box;
        font-size: $queryInputTextFontSize;
        line-height: $selectOptionHeight;
        padding: 0 spacing(2);
        padding-right: $queryInputTextFontSize + $resetQueryButtonRight;
        font-size: 14px;
        border-style: none;
        font-family: $font-family-strong;
        text-transform: uppercase;
        background-color: transparent;
        cursor: text;

        &:focus {
          outline: none;
        }
        &::placeholder {
          color: $darkblue;
        }
      }

      .resetQueryButton {
        position: absolute;
        right: spacing(1);
        top: 0;
        line-height: $selectOptionHeight;
      }
    }
  }
}

.dropdownListWrapper {
  background-color: $filter-background-color;
  text-transform: uppercase;
  position: relative;

  .selectedOption {
    @include clickable;
    padding: $filter-option-padding;
    line-height: $filter-option-lineheight;
    margin: $filter-list-vertical-margin 0;
    background-color: $white;
    color: $darkblue;
    font-size: $filter-option-fontsize;
  }

  .dropdownOptionsList {
    position: absolute;
    left: 0;
    right: 0;

    overflow: hidden;
    margin: 0;
    margin-top: -$filter-list-vertical-margin;
    padding: 0;
    list-style: none;
    transition: max-height 0.3s ease-out;
    max-height: 0;
    border-top: $filters-selected-option-list-border;

    &.openDropdown {
      max-height: 100vh;
    }

    .option {
      @include clickable;
      padding: spacing(2);
      background-color: $theme-background-main;
      color: $darkblue;

      &:hover {
        background-color: $filter-option-hover-background-color;
      }

      &.isSelected {
        color: $filter-option-selected-color;
      }
    }
  }
}
