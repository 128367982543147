@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/shapes/round.scss';
@import '../../Table/variables.scss';
@import './variables.scss';

.usersManagementTable {
  display: flex;
  flex-direction: column;
  flex: 1;

  .row {
    &.header .cell {
      font-family: $font-family-strong;
      font-size: 10px;
    }

    &.body {
      font-family: $font-family-regular;
      color: $darkblue;
      @include clickable();

      &:hover .cell {
        background-color: $rowHoverColor;
      }
    }

    &:hover .edit .button {
      display: flex;
    }

    &:not(:hover) .edit .button {
      display: none;
    }

    .cell {
      &.name,
      &.surname,
      &.email {
        padding-left: $defaultHorizontalCellPadding;
        padding-right: $defaultHorizontalCellPadding;
      }

      &.name {
        flex-basis: $nameCellWidth;
      }

      &.surname {
        flex-basis: $surnameCellWidth;
      }

      &.email {
        flex-basis: $emailCellWidth;
      }

      &.gutter {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;
      }

      &.status {
        flex-basis: $statusCellWidth;
        text-align: center;

        .icon {
          @include round($statusIconSize);
          display: inline-block;

          &.blocked {
            background-color: $darkred;
          }

          &.active {
            background-color: $green;
          }
        }
      }

      &.edit {
        flex-basis: $editCellWidth;
        padding-right: $editCellRightPadding;

        .button {
          flex: 1;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
