@import '../../../styles/variables';
@import '../../../styles/spaced';
@import '../../../styles/spacing';
@import '../../../styles/mixins.scss';

$radioSize: 18px;
$radioButtonBorderWidth: 2px;
$checkedRadioSize: $radioSize - ($radioButtonBorderWidth * 2);

.radioButtonContainer {
  display: flex;
  align-items: center;
}

.label {
  margin-left: spacing(2);
  padding: spacing(1) 0;
  @include clickable();
}

.radioButton {
  white-space: nowrap;
  box-sizing: border-box;
  width: $radioSize;
  height: $radioSize;
  display: inline-block;
  border-radius: $radioSize / 2;
  background-color: $white;
  border: solid $radioButtonBorderWidth $mediumgrey;
}

.radioInput {
  @include visuallyHidden;
  &:checked ~ .radioButton {
    padding: 2px;
    background: none;
    position: relative;
    border: solid $radioButtonBorderWidth $lightblue;

    &:before {
      box-sizing: border-box;
      position: absolute;
      height: $checkedRadioSize;
      width: $checkedRadioSize;
      left: 0px;
      top: 0px;
      border-radius: $checkedRadioSize / 2;
      background-color: $lightblue;
      content: '';
      display: block;
      border: $radioButtonBorderWidth solid $white;
    }
  }
}
