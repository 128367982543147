@import '../../styles/variables';
@import './SideMenuColors.module';

.sideMenu {
  &Content {
    padding: 24px 16px;
    flex-grow: 1;
    flex-shrink: 1;

    display: flex;
    flex-direction: column;

    border-width: 1px 0 0 0;
    border-color: $menuItemBorderColor;
    border-style: solid;
  }
}
