@import '../../styles/variables';
@import '../../styles/spacing';
@import '../../styles/spaced';

$height: 150px;

.withHeight {
  min-height: $height;
  margin: spacing(6) 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .message {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    @include spaced-horizontal(spacing(2));

    font-family: $font-family-regular;
    font-size: 12px;
    color: $theme-color-text;

    &.iconTop {
      align-items: start;
    }

    .icon {
      font-size: 20px;
      margin-right: spacing(1);
    }
  }

  a {
    color: $mediumblue;
  }
}
