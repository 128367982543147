@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/spaced';

$checkboxSize: 18px;
$checkboxPadding: 3px;
$checkboxBorderWidth: 2px;

.checkbox {
  display: block;

  input {
    @include visuallyHidden();
  }

  @include spaced-horizontal(8px);
  white-space: nowrap;

  .checkboxButton {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
    width: $checkboxSize;
    height: $checkboxSize;
    background-color: $white;
    border: solid $checkboxBorderWidth #ccc8c5;

    &.checkboxChecked {
      background-color: #0693ca;
      border-color: transparent;

      > svg {
        position: absolute;
        left: -$checkboxBorderWidth;
        top: -$checkboxBorderWidth;
      }
    }
  }

  .checkboxLabel {
    display: inline-block;
    height: $checkboxSize;
    line-height: $checkboxSize;
    font-size: $default-font-size;
    vertical-align: middle;
    @include overflowEllipsis();
  }

  &.disabled {
    .defaultCheckboxLabel {
      color: $darkgrey;
    }
  }

  &:not(.disabled) {
    .defaultCheckboxLabel {
      color: $darkblue;
    }
  }
}

.checkbox:not(.disabled) {
  @include clickable();
}
