@import '../../styles/mixins';
@import '../../styles/spaced';
@import '../../styles/variables';
@import '../../styles/spacing.scss';
@import '../SideMenu/SideMenuColors.module';

$icon-size: 20px;
$heading-font-size: 20px;

.heading {
  font-size: $heading-font-size;
}

.attachedFileLabel {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-height: $icon-size;
  @include spaced-horizontal(spacing(2));
  @include clickable();

  .iconColumn {
    flex-grow: 0;
    flex-shrink: 0;
    width: $icon-size;
    background-image: url('/assets/icons/plus-circle.svg');
    background-repeat: no-repeat;
  }

  .descriptionColumn {
    flex-grow: 1;
    flex-shrink: 1;

    // The initial setting on flex items is min-width: auto. So, in order for each item
    // to stay within the container we need to give min-width: 0.
    min-width: 0;

    .title {
      line-height: $icon-size;
      font-size: 12px;
    }

    .statusMessage,
    .subtitle {
      font-size: 12px;
      color: $darkgrey;
      font-family: $font-family-regular;
    }

    .statusMessage {
      display: flex;
      @include spaced-horizontal(spacing(2));
      margin: spacing(2) 0;

      .statusText {
        flex-grow: 0;
        flex-shrink: 1;

        // The initial setting on flex items is min-width: auto. So, in order for each item
        // to stay within the container we need to give min-width: 0.
        min-width: 0;

        @include overflowEllipsis();
      }

      .statusIcon {
        flex-grow: 0;
        flex-shrink: 0;
        width: $icon-size;
        height: $icon-size;

        &.ok {
          background-image: url('/assets/icons/oke.svg');
        }

        &.error {
          background-image: url('/assets/icons/error.svg');
        }
      }
    }
  }
}

// Stolen from https://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/
// Should handle cross-browser issues well.
.hiddenFileInput {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.error {
  font-family: $font-family-regular;
  color: $red;
}
