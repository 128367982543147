.virtualTable {
  position: relative;
}

.header {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}

.headerLeft {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
}

.headerRight {
  position: absolute;
  top: 0;
  overflow: hidden;
}

.bodyLeft {
  position: absolute;
  left: 0;
  overflow: hidden;
}

.bodyRight {
  position: absolute;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.innerScroll {
  position: relative;
  width: 100%;
}
