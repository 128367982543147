@import './variables.scss';

@mixin toggleTheme(
  $backgroundColorOff,
  $backgroundColorOn,
  $textColorOff,
  $textColorOn,
  $dotColorOff,
  $dotColorOn,
  $borderOff: none,
  $borderOn: none
) {
  position: absolute;
  opacity: 0;

  .toggleLabel {
    display: inline-block;
  }

  & + .toggleContainer {
    position: relative;
    vertical-align: middle;
    width: $switchContainerWidth;
    height: $switchContainerHeight;
    border-radius: 999px;
    transition-duration: 0.4s;
    border: $borderOff;

    &:not(.disabled) {
      cursor: pointer;
      background-color: $backgroundColorOff;
    }

    &.disabled {
      background-color: $disabledTextColor;
    }

    .toggleDot {
      float: left;
      width: $switchDotWithAndHeight;
      height: $switchDotWithAndHeight;
      border-radius: inherit;
      transition: all 0.4s cubic-bezier(1, 0, 0, 1);
      pointer-events: none;
      margin-top: $switchMargin;
      margin-left: $switchMargin;
      transform: translate3d(0, 0, 0);
      background-color: $dotColorOff;
    }

    .toggleText {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 6px;
      padding: 0;
      margin: 0;
      line-height: $switchContainerHeight + (2 * $switchMargin);
      font-family: $font-family-headings;
      color: $textColorOff;
      font-size: 0.75em;
    }
  }

  &:checked {
    & + .toggleContainer {
      background-position: 0 0;

      .toggleDot {
        -webkit-transform: translate3d($switchContainerHeight, 0, 0);
        transform: translate3d($switchContainerWidth - $switchContainerHeight, 0, 0);
      }

      .toggleText {
        left: 9px;
      }

      &:not(.disabled) {
        background-color: $backgroundColorOn;
        border: $borderOn;

        .toggleDot {
          background-color: $dotColorOn;
        }

        .toggleText {
          color: $textColorOn;
        }
      }
    }
  }
}
