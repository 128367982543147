@import './spacing.scss';
@import './fontSize.scss';
@import './spacing.scss';

@function buttonHorizontalPadding($size) {
  @return spacing($size) * 2;
}

@mixin buttonSize($size) {
  padding: spacing($size) buttonHorizontalPadding($size);
  font-size: $fontSizeButton;
  line-height: $fontSizeButton;
  box-sizing: border-box;
}
