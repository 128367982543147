@import '../../styles/variables.scss';
@import '../../styles/z-indices.scss';
@import '../../styles/spacing.scss';

.modalContainer {
  display: flex;
  z-index: $modalIndex;
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  background-color: $modal-backdrop-background;
  cursor: pointer;

  .withPadding {
    width: auto;
    max-width: $breakpoint-large;
    padding: spacing(4);
    margin: auto;
    z-index: 0;
    flex-grow: 1;

    display: flex;
    cursor: pointer;

    .withCloseButton {
      margin: auto;
      cursor: default;

      &.flexGrow {
        flex-grow: 1;
      }

      .closeRow {
        flex-grow: 0;
        flex-shrink: 0;

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-end;

        cursor: pointer;
      }
    }
  }
}
