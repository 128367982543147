@import '../../styles/spaced';
@import '../../styles/variables';
@import '../../styles/spacing.scss';
@import '../../components/Header/variables.scss';
@import '../../styles/z-indices.scss';

$trigger-icon-size: 18px;

.sideMenuItems {
  display: flex;
  flex-direction: column;

  &Items {
    flex-grow: 1;
    flex-shrink: 1;
  }
}

.bugReporter {
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  @include spaced-vertical-no-margin-collapse(spacing(8));
  padding: spacing(4) spacing(4) spacing(8) spacing(4);
}

.bugReporterIcon {
  color: $theme-errors-main;
}
