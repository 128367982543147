@import '../../../styles/variables.scss';
@import '../../../styles/spacing.scss';
@import '../../../styles/spaced.scss';
@import '../variables.scss';

.modalLayout {
  background-color: $white;

  &.layoutOneColumn {
    @include spaced-vertical($modalDefaultLayoutVerticalSpacing);
    padding: $modalDefaultLayoutColumnPadding;
  }

  &.layoutColumns {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0;
  }

  &:focus {
    outline: 0;
  }
}
