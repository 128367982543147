@import '../../styles/variables.scss';
@import '../../styles/spacing.scss';
@import '../../styles/mixins.scss';
@import '../../styles/shadow.scss';
@import '../../styles/formControlSize.scss';
@import '../../styles/input.scss';
@import './variables.scss';

$spaceBetweenBodyAndHead: 5px;
$borderWidth: 1px;

.container {
  position: relative;

  .head {
    width: 100%;
    @include input();

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @include clickable();

    &:focus {
      position: relative;
      z-index: 2;
    }
  }

  .headIcon {
    width: $chevronSize;
    height: $chevronSize;
  }

  .body {
    position: absolute;
    top: 100%;
    transform: translateY(-1px); // Ensure overlap with `border-bottom` of `.head`.
    left: 0;
    display: flex;
    flex-direction: column;
    min-width: 100%;
    max-height: 240px;
    overflow-y: auto;
    background-color: $white;
    z-index: 1;
    border: $borderWidth solid $inputBorderColor;
    box-sizing: border-box;
  }
}
