@import '../../../styles/spacing.scss';
@import '../../../styles/spaced.scss';
@import '../../../styles/variables.scss';
@import '../variables.scss';

$footerPaddingSpacing: spacing(4);

.footer {
  @include spaced-horizontal($footerPaddingSpacing);
  display: flex;
  justify-content: flex-end;
  padding-top: $modalDefaultLayoutVerticalSpacing;
  border-top: 1px $theme-border-color solid;
}
