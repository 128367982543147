@import '../../styles/variables';
@import '../../styles/mixins';

$spinnerSize: 10px;

.lightBackgroundSpinner {
  @include spinner($spinnerSize, 0);
}

.yellowBackgroundSpinner {
  @include spinner($spinnerSize, 0, $theme-color-active, $darkblue);
}

.spinnerWrapper {
  @include centerWrapper;
}

.loaderInline {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: $spinnerSize;
  height: $spinnerSize;
}
