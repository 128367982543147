@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

@mixin cornerCloseButtonSize($size) {
  @include square($size);
  line-height: $size;

  .cross {
    line-height: $size;
  }
}

$defaultCloseIconButtonSize: 50px;

.closeIcon {
  // Default size:
  @include cornerCloseButtonSize($defaultCloseIconButtonSize);
  background-color: $lightblue;
  text-align: center;
  color: $white;
  @include clickable();

  &.top {
    position: absolute;
    top: 0;
  }

  &.bottom {
    position: absolute;
    bottom: 0;
  }

  &.left {
    position: absolute;
    left: 0;
  }

  &.right {
    position: absolute;
    right: 0;
  }
}
