@import '../../../../styles/variables.scss';
@import '../../../../styles/fontSize.scss';
@import '../../../../styles/spaced.scss';
@import '../../../../styles/spacing.scss';
@import '../../../../styles/mixins.scss';

$spaceBetweenParagraphs: spacing(2);
$spaceBetweenTextAndButton: spacing(6);
$spaceBetweenContentAndFooter: spacing(12);
$columnMinWidth: 320px; // Once the browser cannot fit at least 2 columns at this specified width then the columns will stop and drop into a single column.

.modalContent {
  @include spaced-vertical($spaceBetweenContentAndFooter);
}

.content {
  font-family: $font-family-regular;
  font-size: $fontSizeModal;

  .text {
    column-count: 2;
    column-width: $columnMinWidth;
    column-gap: spacing(4);
  }
}
